import React, { useState, useEffect, useRef } from 'react';
import { Model3DViewer } from './Model3D.tsx';
import { useNavigate } from 'react-router-dom';

interface Department {
  name: string;
  modelPath: string;
  imagePath: string;
  path: string;
}

const departments: Department[] = [
  { name: "Fussball", modelPath: "/models/GBPlayer.glb", imagePath: "/images/club-logo.png", path: "/football" },
  { name: "Volleyball", modelPath: "/models/GBPlayer1.glb", imagePath: "/images/club-logo.png", path: "/volleyball" },
  { name: "Schwimmen", modelPath: "/models/GBPlayer2.glb", imagePath: "/images/club-logo.png", path: "/schwimmen" },
  { name: "Ice Hockey", modelPath: "/models/GBPlayer3.glb", imagePath: "/images/club-logo.png", path: "/icehockey" },
  { name: "Judo", modelPath: "/models/GBPlayer4.glb", imagePath: "/images/club-logo.png", path: "/judo" },
  { name: "Tanzen", modelPath: "/models/GBPlayer5.glb", imagePath: "/images/club-logo.png", path: "/tanzen" },
  { name: "Karate", modelPath: "/models/GBPlayer6.glb", imagePath: "/images/club-logo.png", path: "/karate" },
  { name: "Fitmom", modelPath: "/models/GBPlayer7.glb", imagePath: "/images/club-logo.png", path: "/fitmom" },
  { name: "Gewichtheben", modelPath: "/models/GBPlayer8.glb", imagePath: "/images/club-logo.png", path: "/gewichtheben" },
  { name: "Sport-Tanz", modelPath: "/models/GBPlayer9.glb", imagePath: "/images/club-logo.png", path: "/sporttanz" },
];

export const DepartmentViewer: React.FC = () => {
  const navigate = useNavigate();
  const [selectedDepartment, setSelectedDepartment] = useState(departments[0]);
  const [isModelVisible, setIsModelVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const footballModelRef = useRef<boolean>(false);

  // Check if device is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    handleDepartmentChange(departments[0]);
  }, []);

  const handleDepartmentChange = (dept: Department, event?: React.MouseEvent | React.TouchEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    setIsModelVisible(false);
    setIsLoading(true);
    
    setTimeout(() => {
      setSelectedDepartment(dept);
      // If it's football and the model was already loaded once, show it immediately
      if (dept.name === "Fussball" && footballModelRef.current) {
        setIsLoading(false);
      }
      setIsModelVisible(true);
    }, 100);
  };

  const handleModelClick = (event: React.MouseEvent | React.TouchEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (!isLoading) {
      navigate(selectedDepartment.path);
    }
  };

  const handleModelLoad = () => {
    console.log(`Loaded model for ${selectedDepartment.name}`);
    if (selectedDepartment.name === "Fussball") {
      footballModelRef.current = true;
    }
    setIsLoading(false);
  };

  const renderContent = () => {
    if (!isModelVisible) return null;

    if (isMobile) {
   
        // Render image for other departments
        return (
          <div className="w-full h-full flex items-center justify-center">
            <img 
              src={selectedDepartment.imagePath}
              alt={selectedDepartment.name}
              className="max-w-full max-h-full object-contain"
              onLoad={() => setIsLoading(false)}
            />
          </div>
        );
     
    }

    // Desktop: Render 3D model for all departments
    return (
      <Model3DViewer 
        key={selectedDepartment.modelPath}
        modelPath={selectedDepartment.modelPath} 
        onLoad={handleModelLoad}
      />
    );
  };

  return (
    <div className="flex justify-center items-center w-full">
      <div className="flex flex-row h-[300px] md:h-[530px] w-full max-w-6xl gap-4 p-4">
        {/* Vertical Navigation */}
        <div className="w-48 bg-black/20 rounded-xl backdrop-blur-sm">
          <nav className="flex flex-col p-2">
            {departments.map((dept) => (
              <button
                key={dept.name}
                onClick={(e) => handleDepartmentChange(dept, e)}
                onTouchEnd={(e) => handleDepartmentChange(dept, e)}
                disabled={isLoading && selectedDepartment.name === dept.name}
                className={`
                  text-left px-2 md:px-4 py-1 md:py-3 rounded-lg transition-all duration-200
                  text-[11px] md:text-base select-none touch-none
                  ${selectedDepartment.name === dept.name 
                    ? isLoading 
                      ? 'bg-white/5 text-white/50 cursor-not-allowed'
                      : 'bg-white/10 text-white font-bold' 
                    : 'text-white/70 hover:bg-white/5 hover:text-white'
                  }
                `}
              >
                {dept.name}
              </button>
            ))}
          </nav>
        </div>

        {/* Content Display (3D Model or Image) */}
        <div 
          className="flex-1 bg-black/20 rounded-xl backdrop-blur-sm overflow-hidden cursor-pointer hover:bg-black/30 transition-colors duration-200 relative select-none touch-none"
          onClick={(e) => handleModelClick(e)}
          onTouchEnd={(e) => handleModelClick(e)}
          title={`Click to view ${selectedDepartment.name} department`}
        >
          {renderContent()}
          
          {/* Loading Overlay */}
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-black/40 backdrop-blur-sm">
              <div className="text-white text-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white mx-auto mb-2"></div>
                <p className="text-sm md:text-base">Abteilung bereitet sich vor...</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

