import React, { useEffect, useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { useGLTF, OrbitControls } from '@react-three/drei';
import { Suspense } from 'react';

function Model({ url, onLoad }: { url: string; onLoad: () => void }) {
  const { scene } = useGLTF(url);
  
  useEffect(() => {
    // scene.rotation.y = 0;
    onLoad();
  }, [scene, onLoad]);

  useEffect(() => {
    const rotateModel = setInterval(() => {
      scene.rotation.y += 0.005;
    }, 16);

    return () => clearInterval(rotateModel);
  }, [scene]);

  return <primitive object={scene} scale={15} />;
}

function Loader() {
  return (
    <div style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '2rem',
      fontWeight: 'bold',
    }}>
      <span style={{ color: '#FFD700' }}>Gold</span>
      <span style={{ color: '#0000FF' }}> Blau</span>
    </div>
  );
}

export function ModelViewer({ modelPath, onLoad }: { modelPath: string; onLoad: () => void }) {
  const [isLoading, setIsLoading] = useState(true);

  const handleModelLoad = () => {
    setIsLoading(false);
    onLoad();
  };

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <Canvas camera={{ position: [0, 0, 5], fov: 45 }}>
        <ambientLight intensity={0.5} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
        <pointLight position={[-10, -10, -10]} />
        <Suspense fallback={null}>
          <directionalLight position={[10, 10, 5]} intensity={1} />
          <Model url={modelPath} onLoad={handleModelLoad} />
        </Suspense>
        <OrbitControls enableZoom={false} autoRotate={false} />
      </Canvas>
      <Suspense fallback={<Loader />} />
    </div>
  );
} 