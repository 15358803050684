import React from "react";
import { FloatingNav } from "./floating-navbar.tsx";
import { IconHome, IconMessage, IconUser, IconCalendar, IconSpiral } from "@tabler/icons-react";
import { useNavigate } from 'react-router-dom';

export function FloatingNavDemo() {
  const navigate = useNavigate();

  const navItems = [
    {
      name: "Home",
      link: "/",
      icon: <IconHome className="h-4 w-4 text-white dark:text-white" />,
    },
    {
      name: "Abteilungen",
      link: "/#Abteilungen",
      icon: <IconUser className="h-4 w-4 text-white dark:text-white" />,
    },
    {
      name: "Termine",
      link: "/#Termine",
      icon: (
        <IconCalendar className="h-4 w-4 text-white dark:text-white" />
      ),
    },
    {
      name: "Vorstand",
      link: "/#Vorstand",
      icon: (
        <IconMessage className="h-4 w-4 text-white dark:text-white" />
      ),
    },
    {
      name: "Sponsoren",
      link: "/#Sponsoren",
      icon: (
        <IconSpiral className="h-4 w-4 text-white dark:text-white" />
      ),
    },
  ];

  const handleMemberClick = () => {
    navigate('/kontakt');
  };

  return (
    <div className="relative w-full">
      <FloatingNav 
        navItems={navItems} 
        onMemberClick={handleMemberClick}
      />
    </div>
  );
}

const DummyContent = () => {
  return (
    <div className="grid grid-cols-1 h-[40rem] w-full bg-white dark:bg-black relative border border-neutral-200 dark:border-white/[0.2] rounded-md">
      <div className="inset-0 absolute bg-grid-black/[0.1] dark:bg-grid-white/[0.2]" />
    </div>
  );
};
