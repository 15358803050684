import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import PlayerCard from '../components/Card.tsx';
import { Layout } from '../components/Layout.tsx';
import { Footer } from '../components/Footer.tsx';
import { Player } from '../types/player.ts';
import { io } from 'socket.io-client';

interface Message {
  id: string;
  text: string;
  userName: string;
  timestamp: string;
}

interface RTCPeerConnectionType extends RTCPeerConnection {
  addStream?: (stream: MediaStream) => void;
}

interface BroadcastProps {
  offer: RTCSessionDescriptionInit;
  broadcasterId: string;
}

interface PlayerModalProps {
  player: Player;
  onClose: () => void;
}

interface AttributeBarProps {
  label: string;
  value: number;
  color?: string;
}

interface StarRatingProps {
  rating: number;
  maxStars?: number;
}

const StarRating = ({ rating, maxStars = 5 }: StarRatingProps) => {
  return (
    <div className="flex items-center gap-2">
      <div className="flex gap-1">
        {[...Array(maxStars)].map((_, index) => {
          const isPartialStar = Math.floor(rating) === index && (rating % 1) > 0;
          const isEmpty = index >= rating;
          
          return (
            <div key={index} className="relative">
              {/* Background star (empty) */}
              <svg
                className="w-5 h-5 text-gray-300"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
              </svg>
              
              {/* Foreground star (filled) */}
              {!isEmpty && (
                <div 
                  className="absolute inset-0 overflow-hidden"
                  style={{ 
                    width: isPartialStar ? `${(rating % 1) * 100}%` : '100%'
                  }}
                >
                  <svg
                    className="w-5 h-5 text-yellow-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <span className="text-white/80 text-sm">
        {rating.toFixed(1)}
      </span>
    </div>
  );
};
const AttributeBar = ({ label, value, color = "bg-green-500" }: AttributeBarProps) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(value);
  }, [value]);

  return (
    <div className="flex items-center gap-4">
      <span className="text-white/80 w-24">{label}</span>
      <div className="flex-1 bg-white/20 h-2 rounded-full overflow-hidden">
        <div 
          className={`h-full ${color} transition-all duration-1000`}
          style={{ width: `${width}%` }}
        />
      </div>
      <span className="text-white/80 w-8 text-right">{value}</span>
    </div>
  );
};

const PlayerModal = ({ player, onClose }: PlayerModalProps) => {
  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-blue-400/30 border-2 border-white rounded-lg max-w-md w-full overflow-hidden animate-fadeIn backdrop-blur-sm">
        <div className="relative p-4">
          <img 
            src={player.imageUrl} 
            alt={player.name} 
            className="w-48 h-48 rounded-full object-cover mx-auto border-4 border-white shadow-lg"
          />
          <button 
            onClick={onClose}
            className="absolute top-2 right-2 bg-white/20 backdrop-blur-sm rounded-full p-2 hover:bg-white/40 transition-colors duration-200 border border-white/50"
          >
            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="p-6 text-white">
          <div className="flex items-center gap-4 mb-4">
            <h3 className="text-2xl font-bold">{player.name}</h3>
            <div className="flex items-center gap-2">
              {player.rating && (
                <span className="bg-white/20 backdrop-blur-sm px-3 py-1 rounded-full text-sm border border-white/50">
                  {player.rating}
                </span>
              )}
              <StarRating rating={player.rating || 3.7} />
            </div>
          </div>
          
          <div className="flex items-center gap-2 mb-6">
            {player.nation && (
              <img 
                src={`/images/flags/${player.nation}.png`} 
                alt={player.nation} 
                className="w-6 h-4 object-cover rounded"
              />
            )}
            <span className="text-white/80">{player.nation || 'Germany'}</span>
          </div>

          <div className="space-y-3 mb-6">
            <AttributeBar label="Pace" value={player.pace || 75} color="bg-green-500" />
            <AttributeBar label="Shooting" value={player.shooting || 70} color="bg-blue-500" />
            <AttributeBar label="Passing" value={player.passing || 72} color="bg-yellow-500" />
            <AttributeBar label="Dribbling" value={player.dribbling || 68} color="bg-purple-500" />
            <AttributeBar label="Defense" value={player.defense || 65} color="bg-red-500" />
            <AttributeBar label="Physical" value={player.physical || 73} color="bg-orange-500" />
          </div>

          <p className="text-white/90">{player.description || 'Best player ever.'}</p>
        </div>
      </div>
    </div>
  );
};

const Football = () => {
  const [team1Players, setTeam1Players] = useState<Player[]>([]);
  const [team2Players, setTeam2Players] = useState<Player[]>([]);
  const [currentTeam, setCurrentTeam] = useState<1 | 2>(1);
  const [loading, setLoading] = useState(true);
  const [color1, setColor1] = useState('#8080ff'); //'#8080ff'
  const [color2, setColor2] = useState('#000080'); //'#000080'
  const [degree, setDegree] = useState(141);
  const [showLiveTicker, setShowLiveTicker] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [tickerLoading, setTickerLoading] = useState(false);
  const [socket, setSocket] = useState<any>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [showLiveStream, setShowLiveStream] = useState(false);
  const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null);
  const peerRef = useRef<RTCPeerConnection | null>(null);
  const currentBroadcasterId = useRef<string | null>(null);
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);

  const baseURL = 'https://web-production-8149.up.railway.app'
  // ? 'https://web-production-8149.up.railway.app' //'https://gold-blau.vercel.app' 
  // : 'https://web-production-8149.up.railway.app';//'http://localhost:3000';

  const ChampionsBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: radial-gradient(circle at center, #0b2554 0%, #001233 100%);
  overflow: hidden;
  z-index: -2;
`;

const ChampionsPattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, transparent 0%, rgba(0, 50, 150, 0.1) 100%);
`;

const StarsContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Star = styled.div`
  position: absolute;
  background: #fff;
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);

  /* Top section stars */
  &:nth-child(1) { width: 40px; height: 40px; top: 15%; left: 10%; opacity: 0.8; }
  &:nth-child(2) { width: 30px; height: 30px; top: 25%; left: 30%; opacity: 0.6; }
  &:nth-child(3) { width: 35px; height: 35px; top: 20%; left: 50%; opacity: 0.7; }
  &:nth-child(4) { width: 45px; height: 45px; top: 15%; left: 70%; opacity: 0.8; }
  &:nth-child(5) { width: 35px; height: 35px; top: 25%; left: 90%; opacity: 0.6; }

  /* Middle section stars */
  &:nth-child(6) { width: 25px; height: 25px; top: 45%; left: 5%; opacity: 0.5; }
  &:nth-child(7) { width: 38px; height: 38px; top: 55%; left: 25%; opacity: 0.7; }
  &:nth-child(8) { width: 42px; height: 42px; top: 48%; left: 45%; opacity: 0.8; }
  &:nth-child(9) { width: 28px; height: 28px; top: 52%; left: 65%; opacity: 0.6; }
  &:nth-child(10) { width: 32px; height: 32px; top: 50%; left: 85%; opacity: 0.7; }

  /* Bottom section stars */
  &:nth-child(11) { width: 35px; height: 35px; top: 75%; left: 15%; opacity: 0.7; }
  &:nth-child(12) { width: 28px; height: 28px; top: 80%; left: 35%; opacity: 0.5; }
  &:nth-child(13) { width: 40px; height: 40px; top: 70%; left: 55%; opacity: 0.8; }
  &:nth-child(14) { width: 32px; height: 32px; top: 85%; left: 75%; opacity: 0.6; }
  &:nth-child(15) { width: 36px; height: 36px; top: 78%; left: 95%; opacity: 0.7; }

  /* Small decorative stars */
  &:nth-child(16) { width: 15px; height: 15px; top: 35%; left: 18%; opacity: 0.4; }
  &:nth-child(17) { width: 18px; height: 18px; top: 62%; left: 88%; opacity: 0.5; }
  &:nth-child(18) { width: 12px; height: 12px; top: 40%; left: 92%; opacity: 0.3; }
  &:nth-child(19) { width: 16px; height: 16px; top: 88%; left: 42%; opacity: 0.4; }
  &:nth-child(20) { width: 14px; height: 14px; top: 32%; left: 72%; opacity: 0.3; }
`;

const CurvedLines = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 50%;
  }

  &::before {
    transform: rotate(30deg);
  }

  &::after {
    transform: rotate(-30deg);
  }
`;


  const loadTeamData = async (teamNumber: 1 | 2) => {
    setLoading(true);
    try {

      console.log(`${baseURL}/api/squad/team${teamNumber}`)
      const response = await fetch(`${baseURL}/api/squad/team${teamNumber}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error!`);
      }
      
      const data = await response.json();
      console.log(data)
      const squad = teamNumber === 1 ? data.team1 : data.team2;
      
      const allPlayers = [
        ...squad.mainSquad.map((player: Player) => ({ ...player, status: 'main' })),
        ...squad.bench.map((player: Player) => ({ ...player, status: 'bench' }))
      ];

      if (teamNumber === 1) {
        setTeam1Players(allPlayers);
      } else {
        setTeam2Players(allPlayers);
      }
    } catch (error) {
      console.error(`Error fetching team ${teamNumber} squad:`, error);
    } finally {
      setLoading(false);
    }
  };

  const handleTeamSwitch = (teamNumber: 1 | 2) => {
    setCurrentTeam(teamNumber);
    loadTeamData(teamNumber);
  };

  useEffect(() => {
    loadTeamData(1); // Load initial team data
  }, [baseURL]);

  useEffect(() => {
    const newSocket = io(baseURL);
    setSocket(newSocket);
    setTickerLoading(true);

    newSocket.on('connect', () => {
      console.log('Connected to socket');
      newSocket.emit('getRecentMessages');
    });

    newSocket.on('recentMessages', (recentMessages: Message[]) => {
      console.log('Received recent messages:', recentMessages);
      setMessages(recentMessages);
      setTickerLoading(false);
    });

    newSocket.on('receiveMessage', (newMessage: Message) => {
      console.log('Received new message:', newMessage);
      setMessages(prevMessages => [...prevMessages, newMessage]);
    });

    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
  }, [baseURL]);

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    if (showLiveTicker) {
      setTimeout(scrollToBottom, 100);
    }
  }, [showLiveTicker]);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEndRef.current) {
        const tickerContainer = messagesEndRef.current.parentElement?.parentElement;
        if (tickerContainer) {
          tickerContainer.scrollTop = tickerContainer.scrollHeight;
        }
      }
    }, 50);
  };

  const getPlayerByPosition = (position: string, team: 1 | 2) => {
    const players = team === 1 ? team1Players : team2Players;
    return players.find(player => 
      player.position === position && player.status === 'main'
    ) || {
      _id: `default-${position}`,
      name: 'Unknown',
      position: position,
      imageUrl: '/images/player.jpg',
      team: team,
      status: 'main'
    } as Player;
  };

  const defaultBenchPlayer = (index: number) => ({
    _id: `bench-placeholder-${index}`,
    name: 'Bench Player',
    position: 'SUB',
    imageUrl: '/images/player.jpg',
  });
 
  const getBenchPlayers = (team: 1 | 2) => {
    const players = team === 1 ? team1Players : team2Players;
    const benchPlayers = players.filter(player => player.status === 'bench') || [];
    
    // const placeholdersNeeded = 8 - benchPlayers.length;
    // for (let i = 0; i < placeholdersNeeded; i++) {
    //   benchPlayers.push({ 
    //     ...defaultBenchPlayer(benchPlayers.length + i),
    //     team,
    //     status: 'bench'
    //   });
    // }
    return benchPlayers.slice(0, 8);
  };

  const benchStartDelay = 11000;


  // const setupPeerConnection = () => {
  //   const configuration = {
  //     iceServers: [
  //       { urls: 'stun:stun.l.google.com:19302' },
  //       { urls: 'stun:stun1.l.google.com:19302' }
  //     ]
  //   };

  //   peerRef.current = new RTCPeerConnection(configuration);

  //   socket.on('offer', async (id, description) => {
  //     try {
  //       if (!peerRef.current) {
  //         console.log('Creating new peer connection for offer');
  //         peerRef.current = new RTCPeerConnection(configuration);
          
  //         // Add connection state monitoring
  //         peerRef.current.addEventListener('connectionstatechange', () => {
  //           console.log('Connection state changed:', peerRef.current?.connectionState);
  //         });

  //         peerRef.current.addEventListener('iceconnectionstatechange', () => {
  //           console.log('ICE connection state changed:', peerRef.current?.iceConnectionState);
  //         });

         
  //         peerRef.current.addEventListener('track', event => {
  //           console.log('Received track:', {
  //             kind: event.track?.kind,
  //             enabled: event.track?.enabled,
  //             readyState: event.track?.readyState,
  //             muted: event.track?.muted,
  //             id: event.track?.id,
  //             streamId: event.streams[0]?.id
  //           });
            
  //           // Monitor track status
  //           event.track?.addEventListener('ended', () => {
  //             console.log('Remote track ended:', {
  //               kind: event.track?.kind
  //             });
  //           });
            
  //           event.track?.addEventListener('mute', () => {
  //             console.log('Remote track muted:', {
  //               kind: event.track?.kind
  //             });
  //           });
            
  //           event.track?.addEventListener('unmute', () => {
  //             console.log('Remote track unmuted:', {
  //               kind: event.track?.kind
  //             });
  //           });
            
  //           if (event.streams && event.streams[0]) {
  //             console.log('Setting remote stream:', {
  //               streamId: event.streams[0].id,
  //               trackCount: event.streams[0].getTracks().length
  //             });
  //             setRemoteStream(event.streams[0]);
  //           } else {
  //             console.warn('Received track without stream');
  //           }
  //         });

  //         peerRef.current.addEventListener('icecandidate', event => {
  //           if (event.candidate) {
  //             console.log('Sending ICE candidate to broadcaster');
  //             socket.emit('candidate', id, event.candidate);
  //           }
  //         });
  //       }

  //       console.log('Setting remote description from offer');
  //       await peerRef.current.setRemoteDescription(description);
        
  //       console.log('Creating answer');
  //       const answer = await peerRef.current.createAnswer();
        
  //       console.log('Setting local description');
  //       await peerRef.current.setLocalDescription(answer);
        
  //       console.log('Sending answer to broadcaster');
  //       socket.emit('answer', id, peerRef.current.localDescription);
  //     } catch (err) {
  //       console.error('Error handling offer:', err);
  //     }
  //   });

  //   socket.on('candidate', async (id, candidate) => {
  //     try {
  //       if (peerRef.current) {
  //         console.log('Adding ICE candidate');
  //         await peerRef.current.addIceCandidate(candidate);
  //         console.log('Successfully added ICE candidate');
  //       }
  //     } catch (err) {
  //       console.error('Error adding ICE candidate:', err);
  //     }
  //   });

  //   socket.on('broadcasterDisconnected', () => {
  //     if (peerRef.current) {
  //       peerRef.current.close();
  //       peerRef.current = null;
  //     }
  //     setRemoteStream(null);
  //   });

  //   return peerRef.current;
  // };

  // const handleNewBroadcast = async ({ offer, broadcasterId }: BroadcastProps) => {
  //   console.log('New broadcast received', { broadcasterId });
  //   try {
  //     currentBroadcasterId.current = broadcasterId;
  //     const pc = setupPeerConnection();

  //     await pc.setRemoteDescription(new RTCSessionDescription(offer));
  //     const answer = await pc.createAnswer();
  //     await pc.setLocalDescription(answer);
      
  //     socket?.emit('viewer-answer', {
  //       answer,
  //       broadcasterId: currentBroadcasterId.current
  //     });
  //   } catch (err) {
  //     console.error('Error handling new broadcast:', err);
  //   }
  // };

  useEffect(() => {
    if (!socket || !showLiveStream) return;

    // const handleWatcher = async (id: string) => {
      // console.log('New watcher:', id);
      // const pc = setupPeerConnection();
      

    // };

    // const handleAnswer = async (id: string, description: RTCSessionDescription) => {
    //   console.log('Received answer from:', id);
    //   try {
    //     await peerRef.current?.setRemoteDescription(description);
    //   } catch (err) {
    //     console.error('Error setting remote description:', err);
    //   }
    // };

    // const handleCandidate = async (id: string, candidate: RTCIceCandidate) => {
    //   console.log('Received ICE candidate for:', id);
    //   try {
    //     await peerRef.current?.addIceCandidate(candidate);
    //   } catch (err) {
    //     console.error('Error adding ICE candidate:', err);
    //   }
    // };

    if (showLiveStream) {
      try {
       
        const configuration = {
          iceServers: [
            { urls: 'stun:stun.l.google.com:19302' },
            { urls: 'stun:stun1.l.google.com:19302' }
          ]
        };
    
        peerRef.current = new RTCPeerConnection(configuration);
    
        socket.on('offer', async (id, description) => {
          try {
            
              
              // Add connection state monitoring
              peerRef.current?.addEventListener('connectionstatechange', () => {
                console.log('Connection state changed:', peerRef.current?.connectionState);
              });
    
              peerRef.current?.addEventListener('iceconnectionstatechange', () => {
                console.log('ICE connection state changed:', peerRef.current?.iceConnectionState);
              });
    
              peerRef.current?.addEventListener('track', event => {
                console.log('Received track:', {
                  kind: event.track?.kind,
                  enabled: event.track?.enabled,
                  readyState: event.track?.readyState,
                  muted: event.track?.muted,
                  id: event.track?.id,
                  streamId: event.streams[0]?.id
                });
                
                // Monitor track status
                event.track?.addEventListener('ended', () => {
                  console.log('Remote track ended:', {
                    kind: event.track?.kind
                  });
                });
                
                event.track?.addEventListener('mute', () => {
                  console.log('Remote track muted:', {
                    kind: event.track?.kind
                  });
                });
                
                event.track?.addEventListener('unmute', () => {
                  console.log('Remote track unmuted:', {
                    kind: event.track?.kind
                  });
                });
                
                if (event.streams && event.streams[0]) {
                  console.log('Setting remote stream:', {
                    streamId: event.streams[0].id,
                    trackCount: event.streams[0].getTracks().length
                  });
                  setRemoteStream(event.streams[0]);
                } else {
                  console.warn('Received track without stream');
                }
              });
    
              peerRef.current?.addEventListener('icecandidate', event => {
                if (event.candidate) {
                  console.log('Sending ICE candidate to broadcaster');
                  socket.emit('candidate', id, event.candidate);
                }
              });
           
    
            console.log('Setting remote description from offer');
            await peerRef.current?.setRemoteDescription(description);
            
            console.log('Creating answer');
            const answer = await peerRef.current?.createAnswer();
            
            console.log('Setting local description');
            await peerRef.current?.setLocalDescription(answer);
            
            console.log('Sending answer to broadcaster');
            socket.emit('answer', id, peerRef.current?.localDescription);
          } catch (err) {
            console.error('Error handling offer:', err);
          }
        });
    
        socket.on('candidate', async (id, candidate) => {
          try {
            if (peerRef.current) {
              console.log('Adding ICE candidate');
              await peerRef.current.addIceCandidate(candidate);
              console.log('Successfully added ICE candidate');
            }
          } catch (err) {
            console.error('Error adding ICE candidate:', err);
          }
        });
    
        socket.on('broadcasterDisconnected', () => {
          if (peerRef.current) {
            peerRef.current.close();
            peerRef.current = null;
          }
          setRemoteStream(null);
        });


        socket.emit('watcher');

      } catch (err) {
        console.error('Error setting up broadcast:', err);
      }
    

    }

    return () => {

      
      if (peerRef.current) {
        peerRef.current.close();
      }
      socket.current?.disconnect();
      
      if (remoteStream) {
        remoteStream.getTracks().forEach(track => track.stop());
        setRemoteStream(null);
      }
    };
  }, [showLiveStream]);
  const getTrainerByTeam = (team: 1 | 2) => {
    return team === 1 
      ? {
          _id: 'trainer-1',
          name: "Гена",
          position: "Manager",
          imageUrl: "/images/trainer.jpg",
          description: "Head Coach of Team 1",

          status: 'main'
        }
      : {
          _id: 'trainer-2',
          name: "Олег",
          position: "Manager",
          imageUrl: "/images/trainer2.jpg",
          description: "Head Coach of Team 2",
 
          status: 'main'
        };
  };
  
  // Add this new function to get players by position index
  const getPlayerByPositionAndIndex = (position: string, team: 1 | 2, index: number = 0) => {
    const players = team === 1 ? team1Players : team2Players;
    const positionPlayers = players.filter(player => 
      player.position === position && player.status === 'main'
    );
    
    return positionPlayers[index] || {
      name: 'Unknown',
      position: position,
      imageUrl: '/images/player.jpg',
      team: team
    };
  };

  const renderPlayerCard = (player: Player, delay: number) => (
    <div 
      onClick={() => setSelectedPlayer(player)} 
      className="transform transition-all duration-300 hover:scale-110 hover:shadow-xl cursor-pointer"
    >
      <PlayerCard {...player} delay={delay} />
    </div>
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout color1={color1} color2={color2} degree={degree}>
         <ChampionsBackground>
      <ChampionsPattern />
      <CurvedLines />
      <StarsContainer>
        {[...Array(20)].map((_, index) => (
          <Star key={index} />
        ))}
      </StarsContainer>
    </ChampionsBackground>
      <div className="container mx-auto p-8 overflow-x-hidden">
      <div className="flex flex-col items-center mb-8">
          <div className="flex gap-4 mb-4">
            <button
              onClick={() => handleTeamSwitch(1)}
              className={`px-6 py-2 rounded-full transition-all duration-300 ${
                currentTeam === 1 
                  ? 'bg-blue-400/30 text-white shadow-lg scale-105' 
                  : 'bg-white text-blue-600 hover:bg-blue-100'
              }`}
            >
              Team 1
            </button>
            <button
              onClick={() => handleTeamSwitch(2)}
              className={`px-6 py-2 rounded-full transition-all duration-300 ${
                currentTeam === 2 
                  ? 'bg-blue-600 text-white shadow-lg scale-105' 
                  : 'bg-white text-blue-600 hover:bg-blue-100'
              }`}
            >
              Team 2
            </button>
          </div>

          <div className="flex gap-4">
            <button
              onClick={() => setShowLiveTicker(!showLiveTicker)}
              className="px-6 py-2 rounded-full transition-all duration-300 bg-white text-blue-600 hover:bg-blue-100"
            >
              {showLiveTicker ? 'Hide LiveTicker' : 'Show LiveTicker'}
            </button>

            <button
              onClick={() => setShowLiveStream(!showLiveStream)}
              className="px-6 py-2 rounded-full transition-all duration-300 bg-white text-blue-600 hover:bg-blue-100"
            >
              {showLiveStream ? 'Hide Live Stream' : 'Show Live Stream'}
            </button>
          </div>

          {showLiveStream && (
       <div className="w-full max-w-2xl mt-4 bg-black rounded-lg overflow-hidden aspect-video">
       {remoteStream ? (
         <video
           autoPlay
           playsInline
           ref={(video) => {
             if (video && remoteStream) {
               video.srcObject = remoteStream;
               video.onloadedmetadata = () => {
                 console.log('Video metadata loaded');
                 video.play().catch(e => console.error('Error playing video:', e));
               };
               video.onerror = (e) => console.error('Video error:', e);
             }
           }}
           className="w-full h-full object-cover"
         />
       ) : (
         <div className="w-full h-full flex items-center justify-center text-white">
           Waiting for broadcast...
         </div>
       )}
     </div>
          )}

          {showLiveTicker && (
            <div className="w-full max-w-2xl mt-4 bg-black bg-opacity-30 rounded-lg p-4 backdrop-blur-sm">
              <div 
                className="overflow-y-auto h-[400px] hide-scrollbar"
                style={{ 
                  overscrollBehavior: 'contain',
                  msOverflowStyle: 'none',
                  scrollbarWidth: 'none'
                }}
              >
                {tickerLoading ? (
                  <div className="text-white text-center">Loading messages...</div>
                ) : (
                  <div className="space-y-2">
                    {messages.slice(-10).map((message) => (
                      <div
                        key={message.id}
                        className="border-b border-white/10 pb-2 last:border-b-0 animate-fadeIn"
                      >
                        <div className="flex justify-between text-sm text-white/80">
                          <span className="font-semibold">{message.userName}</span>
                          <span>{message.timestamp}</span>
                        </div>
                        <div className="mt-1 text-white">
                          {message.text}
                        </div>
                      </div>
                    ))}
                    <div ref={messagesEndRef} />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col justify-center items-center gap-8">
          <div className="relative w-[400px] md:w-[500px] aspect-[12/20] md:aspect-[15/20] bg-blue-400/30 border-2 border-white rounded-lg backdrop-blur-sm">
            <div className="absolute inset-0 flex items-center justify-center">
           
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-32 h-32 border-2 border-white rounded-full" />
                <div className="absolute left-0 top-1/2 w-full h-0.5 bg-white" />
                <div className="absolute top-0 left-1/2 -translate-x-1/2 w-80 h-32" style={{
                  borderLeft: '2px solid white',
                  borderRight: '2px solid white',
                  borderBottom: '2px solid white'
                }} />
                <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-80 h-32" style={{
                  borderLeft: '2px solid white',
                  borderRight: '2px solid white',
                  borderTop: '2px solid white'
                }} />
                <div className="absolute top-0 left-1/2 -translate-x-1/2 w-40 h-16" style={{
                  borderLeft: '2px solid white',
                  borderRight: '2px solid white',
                  borderBottom: '2px solid white'
                }} />
                <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-40 h-16" style={{
                  borderLeft: '2px solid white',
                  borderRight: '2px solid white',
                  borderTop: '2px solid white'
                }} />

                <div 
                  className="absolute top-32 left-1/2 -translate-x-1/2 w-24 h-10"
                  style={{
                    borderBottom: '2px solid white',
                    borderLeft: '2px solid white',
                    borderRight: '2px solid white',
                    borderRadius: '0 0 48px 48px'
                  }}
                />
                <div 
                  className="absolute bottom-32 left-1/2 -translate-x-1/2 w-24 h-10"
                  style={{
                    borderTop: '2px solid white',
                    borderLeft: '2px solid white',
                    borderRight: '2px solid white',
                    borderRadius: '48px 48px 0 0'
                  }}
                />

                <div className="absolute top-[15%] left-[20%] -translate-x-1/2 z-10">
                  {renderPlayerCard(getPlayerByPosition('LW', currentTeam), 0)}
                </div>
                <div className="absolute top-[8%] left-1/2 -translate-x-1/2 z-10">
                  {renderPlayerCard(getPlayerByPosition('ST', currentTeam), 1000)}
                </div>
                <div className="absolute top-[15%] left-[80%] -translate-x-1/2 z-10">
                  {renderPlayerCard(getPlayerByPosition('RW', currentTeam), 2000)}
                </div>

                <div className="absolute top-[35%] left-[25%] -translate-x-1/2 z-10">
                  {renderPlayerCard(getPlayerByPosition('LM', currentTeam), 3000)}
                </div>
                <div className="absolute top-[35%] left-1/2 -translate-x-1/2 z-10">
                  {renderPlayerCard(getPlayerByPosition('CM', currentTeam), 4000)}
                </div>
                <div className="absolute top-[35%] left-[75%] -translate-x-1/2 z-10">
                  {renderPlayerCard(getPlayerByPosition('RM', currentTeam), 5000)}
                </div>

                <div className="absolute top-[60%] left-[15%] -translate-x-1/2 z-10">
                  {renderPlayerCard(getPlayerByPosition('LB', currentTeam), 6000)}
                </div>
                <div className="absolute top-[60%] left-[38%] -translate-x-1/2 z-10">
                  {renderPlayerCard(getPlayerByPositionAndIndex('CB', currentTeam, 0), 7000)}
                </div>
                <div className="absolute top-[60%] left-[62%] -translate-x-1/2 z-10">
                  {renderPlayerCard(getPlayerByPositionAndIndex('CB', currentTeam, 1), 8000)}
                </div>
                <div className="absolute top-[60%] left-[83%] -translate-x-1/2 z-10">
                  {renderPlayerCard(getPlayerByPosition('RB', currentTeam), 9000)}
                </div>

                <div className="absolute top-[80%] left-1/2 -translate-x-1/2 z-10">
                  {renderPlayerCard(getPlayerByPosition('GK', currentTeam), 10000)}
                </div>
              
            </div>
          </div>


          <div className="w-full flex flex-col items-center gap-4">
            <h3 className="text-white font-bold text-xl mb-2 text-left">Bank</h3>
            <div className="grid grid-cols-4 gap-[1px]  w-[400px] md:w-[500px] cursor-pointer">
              {getBenchPlayers(currentTeam).map((player, index) => (
                <div 
                  key={player._id || index}
                  className="transform transition-all duration-500"
                  onClick={() => setSelectedPlayer(player)} 
                >
                  <PlayerCard 
                    {...player} 
                    delay={benchStartDelay + (index * 1000)} 
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="w-full flex flex-col items-center gap-4 my-8">
            <h3 className="text-white font-bold text-xl mb-2 text-left">Trainer</h3>
            <div className="w-[400px] md:w-[500px]  cursor-pointer"
                  onClick={() => setSelectedPlayer(getTrainerByTeam(currentTeam))}>
                  <PlayerCard 
                    {...getTrainerByTeam(currentTeam)}
                    delay={16000}
                  />
            </div>
          </div>
        </div>


      </div>
      <Footer />

      {selectedPlayer && (
        <PlayerModal 
          player={selectedPlayer} 
          onClose={() => setSelectedPlayer(null)} 
        />
      )}
    </Layout>
  );
};

const styles = `
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .animate-fadeIn {
    animation: fadeIn 0.3s ease-out forwards;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
`;

export default Football; 