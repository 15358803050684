import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Layout } from '../components/Layout.tsx';
import { Footer } from '../components/Footer.tsx';


interface Sparkle {
  left: number;
  top: number;
  size: number;
  delay: number;
}

const PageContainer = styled.div`
  margin: 0;
  padding: 0;
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(180deg, #000428 0%, #004e92 100%);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

const sparkle = keyframes`
  0%, 100% { opacity: 0.2; transform: scale(0.8); }
  50% { opacity: 1; transform: scale(1.2); }
`;

const Sparkle = styled.div<{ size: number; delay: number }>`
  position: absolute;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background: white;
  border-radius: 50%;
  animation: ${sparkle} ${props => 2 + props.delay}s ease-in-out infinite;
  filter: blur(1px);
`;
// min-height: 100vh;
const MainContent = styled.div`
  min-height: 85vh;
  width: 100%;
  position: relative;
  z-index: 1;
`;

const Content = styled.div`
  position: relative;
  color: white;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  
  img {
    max-width: 200px;
    height: auto;
  }
`;

const ContactInfo = styled.div`
  text-align: center;
  color: white;
  margin: 2rem auto;
  max-width: 600px;
  padding: 0 20px;
  
  h2 {
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
  }

  p {
    margin: 0.5rem 0;
    font-size: 1.1rem;
    line-height: 1.6;
  }

  a {
    color: white;
    text-decoration: none;
    transition: opacity 0.3s ease;
    
    &:hover {
      opacity: 0.8;
    }
  }
`;

const MapLink = styled.a`
  display: inline-block;
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  border: 2px solid white;
  border-radius: 5px;
  transition: all 0.3s ease;
  
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const StadiumShow: React.FC = () => {
  const generateSparkles = (): Sparkle[] => {
    const sparkles: Sparkle[] = [];
    for (let i = 0; i < 100; i++) {
      sparkles.push({
        left: Math.random() * 100,
        top: Math.random() * 70,
        size: Math.random() * 3 + 1,
        delay: Math.random() * 2,
      });
    }
    return sparkles;
  };

  return (
    <>
      <Layout color1={'#000428'} color2={'#004e92'} degree={45}>
      <PageContainer>
        {generateSparkles().map((sparkle, index) => (
          <Sparkle
            key={`sparkle-${index}`}
            style={{
              left: `${sparkle.left}%`,
              top: `${sparkle.top}%`,
            }}
            size={sparkle.size}
            delay={sparkle.delay}
          />
        ))}
      </PageContainer>
      <MainContent>
        <Content>
          <LogoContainer>
            <img src='/images/club-logo.png' alt="SV Gold-Blau Augsburg Logo" />
          </LogoContainer>
          <ContactInfo>
            <h2>Adresse:</h2>
            <p>SV Gold-Blau Augsburg e. V</p>
            <p>Unterer Talweg 100</p>
            <p>86179 Augsburg</p>
            
            <MapLink 
              href="https://www.google.com/maps/search/?api=1&query=Unterer+Talweg+100+86179+Augsburg" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Auf der Karte zeigen
            </MapLink>
            
            <p>
              <strong>Telefon:</strong> <a href="tel:+491717834586">0171 - 783 45 86</a>
            </p>
            <p>
              <strong>E-Mail:</strong>{' '}
              <a href="mailto:rudolf.strohmaier@t-online.de">rudolf.strohmaier@t-online.de</a>
            </p>
          </ContactInfo>
        </Content>
      </MainContent>
      <Footer />
      </Layout>
    </>
  );
};

export default StadiumShow;