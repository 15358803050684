import React, { useEffect } from "react";
import { useGLTF, OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import * as THREE from "three";



const Fallback = () => (
  <div className="flex items-center justify-center w-full h-full min-h-[6rem] bg-transparent rounded-xl">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-white" />
  </div>
);

function Model({ url, onLoad }: { url: string; onLoad?: () => void }) {
  const { scene, animations } = useGLTF(url, true); // true enables caching
  
  const hasLoaded = React.useRef(false);
  
  useEffect(() => {
    if (!hasLoaded.current && scene) {
      console.log('Model scene loaded:', url);
      hasLoaded.current = true;
      
 
      
      onLoad?.();
    }
  }, [scene, onLoad, url]);

  React.useEffect(() => {
    if (animations.length > 0) {
      const mixer = new THREE.AnimationMixer(scene);
      const clock = new THREE.Clock();
      
      animations.forEach((clip) => {
        const modifiedClip = THREE.AnimationClip.parse(THREE.AnimationClip.toJSON(clip));
        
        modifiedClip.tracks = modifiedClip.tracks.filter(track => 
          !track.name.toLowerCase().includes('position') &&
          !track.name.toLowerCase().includes('root')
        );
        
        const action = mixer.clipAction(modifiedClip);
        action.setLoop(THREE.LoopRepeat, Infinity);
        action.setDuration(1);
        action.play();
      });

      const animate = () => {
        const delta = clock.getDelta();
        mixer.update(delta * 1);
        requestAnimationFrame(animate);
      };
      animate();

      return () => {
        mixer.stopAllAction();
        clock.stop();
      };
    }
  }, [scene, animations]);

  return <primitive object={scene} scale={28} position={[0, -2, 0]} />;
}

// Add cache preloading
// useGLTF.preload('/models/GBLogo.glb');
// useGLTF.preload('/models/GBPlayer.glb');
// useGLTF.preload('/models/GBPlayer1.glb');
// useGLTF.preload('/models/GBPlayer2.glb');
// useGLTF.preload('/models/GBPlayer3.glb');
// useGLTF.preload('/models/GBPlayer4.glb');
// useGLTF.preload('/models/GBPlayer5.glb');
// useGLTF.preload('/models/GBPlayer6.glb');
// useGLTF.preload('/models/GBPlayer7.glb');
// useGLTF.preload('/models/GBPlayer8.glb');
// useGLTF.preload('/models/GBPlayer9.glb');

function Scene({ modelPath, onLoad }: { modelPath: string; onLoad?: () => void }) {
  
  return (
    <>
      <ambientLight intensity={0.5} />
      <directionalLight position={[10, 10, 5]} intensity={1} />
      <Model url={modelPath} onLoad={onLoad} />
      <OrbitControls 
        autoRotate={false}
        enableZoom={true}
        makeDefault 
        minPolarAngle={0}
        maxPolarAngle={Math.PI / 2}
      />
    </>
  );
}

export function Model3DViewer({ modelPath, onLoad }: { modelPath: string; onLoad?: () => void }) {
  const loadedRef = React.useRef(false);

  const handleLoad = React.useCallback(() => {
    if (!loadedRef.current) {
      console.log('Model3DViewer handleLoad:', modelPath);
      loadedRef.current = true;
      onLoad?.();
    }
  }, [onLoad, modelPath]);

  return (
    <Suspense fallback={<Fallback />}>
      <div className="w-full h-full min-h-[6rem]">
        <Canvas 
          camera={{ 
            position: [15, 15, 30],
            fov: 45,
            near: 0.1,
            far: 1000
          }}
        >
          <Scene modelPath={modelPath} onLoad={handleLoad} />
        </Canvas>
      </div>
    </Suspense>
  );
} 