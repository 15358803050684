import React from 'react';
import { FaInstagram, FaYoutube, FaFacebook, FaAppStore, FaGooglePlay } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer className="w-full py-4 px-6 mt-auto" style={{ background: 'inherit' }}>
      <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center gap-4">
        {/* Copyright */}
        <div className="text-white text-[10px] sm:text-sm md:text-base order-2 sm:order-1">
          © {new Date().getFullYear()} SV Gold-Blau Augsburg e. V. Alle Rechte vorbehalten.
        </div>

        {/* Middle Links */}
        <div className="flex gap-4 sm:gap-6 text-white text-[10px] sm:text-sm md:text-base order-1 sm:order-2">
          <Link 
            to="/datenschutz" 
            className="hover:text-[#FFD700] transition-colors"
          >
            Datenschutz
          </Link>
          <span className="text-white/50">|</span>
          <Link 
            to="/impressum" 
            className="hover:text-[#FFD700] transition-colors"
          >
            Impressum
          </Link>
          <span className="text-white/50">|</span>
          <Link 
            to="/kontakt" 
            className="hover:text-[#FFD700] transition-colors"
          >
            Kontakt
          </Link>
        </div>

        {/* Social Media Icons */}
        <div className="flex gap-2 sm:gap-4 order-3">
          <a 
            href="https://instagram.com" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-white hover:text-[#FFD700] transition-colors"
          >
            <FaInstagram className="w-4 h-4 sm:w-6 sm:h-6" />
          </a>
          <a 
            href="https://youtube.com" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-white hover:text-[#FFD700] transition-colors"
          >
            <FaYoutube className="w-4 h-4 sm:w-6 sm:h-6" />
          </a>
          <a 
            href="https://facebook.com" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-white hover:text-[#FFD700] transition-colors"
          >
            <FaFacebook className="w-4 h-4 sm:w-6 sm:h-6" />
          </a>
          <a 
            href="https://apps.apple.com/your-app-link" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-white hover:text-[#FFD700] transition-colors"
          >
            <FaAppStore className="w-4 h-4 sm:w-6 sm:h-6" />
          </a>
          <a 
            href="https://play.google.com/store/your-app-link" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-white hover:text-[#FFD700] transition-colors"
          >
            <FaGooglePlay className="w-4 h-4 sm:w-6 sm:h-6" />
          </a>
        </div>
      </div>
    </footer>
  );
}; 