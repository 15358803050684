import React from 'react';
import { FloatingNavDemo } from './Navbar.tsx';

interface LayoutProps {
  children: React.ReactNode;
  color1?: string;
  color2?: string;
  degree?: number;
  isLoading?: boolean;
}

export const Layout = ({ children, color1 = '#ff0000', color2 = '#0000ff', degree = 45 ,  isLoading = false }: LayoutProps) => {
  React.useEffect(() => {
    document.body.style.background = `linear-gradient(${degree}deg, ${color1}, ${color2})`;
    return () => {
      document.body.style.background = '';
    };
  }, [color1, color2, degree]);

  return (
    <div className="min-h-screen w-full relative">
         {!isLoading && (
      <div className="fixed w-full top-0 z-50">
        <FloatingNavDemo />
      </div>
         )}
      <div className="pt-20 w-full">
        {children}
      </div>
    </div>
  );
}; 