import React from 'react';
import styled from 'styled-components';
import { Layout } from '../components/Layout.tsx';
import { Footer } from '../components/Footer.tsx';

const PageContainer = styled.div`
  margin: 0;
  padding: 0;
  min-height: 85vh;
  width: 100vw;
  background: radial-gradient(circle at center, #0b2554 0%, #001233 100%);
  overflow: hidden;
  position: fixed; // Changed to fixed
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; // Ensure background stays behind content
`;

const ChampionsPattern = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, transparent 0%, rgba(0, 50, 150, 0.1) 100%);
`;

const StarsContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const Star = styled.div`
  position: absolute;
  background: #fff;
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  animation: twinkle 4s ease-in-out infinite;
  
  /* Add random animation delay for each star */
  &:nth-child(1) { animation-delay: 0.5s; }
  &:nth-child(2) { animation-delay: 1s; }
  &:nth-child(3) { animation-delay: 1.5s; }
  &:nth-child(4) { animation-delay: 2s; }
  &:nth-child(5) { animation-delay: 2.5s; }
  &:nth-child(6) { animation-delay: 3s; }
  &:nth-child(7) { animation-delay: 3.5s; }
  &:nth-child(8) { animation-delay: 0.2s; }
  &:nth-child(9) { animation-delay: 0.7s; }
  &:nth-child(10) { animation-delay: 1.2s; }
  &:nth-child(11) { animation-delay: 1.7s; }
  &:nth-child(12) { animation-delay: 2.2s; }
  &:nth-child(13) { animation-delay: 2.7s; }
  &:nth-child(14) { animation-delay: 3.2s; }
  &:nth-child(15) { animation-delay: 3.7s; }
  &:nth-child(16) { animation-delay: 0.4s; }
  &:nth-child(17) { animation-delay: 0.9s; }
  &:nth-child(18) { animation-delay: 1.4s; }
  &:nth-child(19) { animation-delay: 1.9s; }
  &:nth-child(20) { animation-delay: 2.4s; }

  /* Keep existing star positions and sizes */
  /* Top section stars */
  &:nth-child(1) { width: 40px; height: 40px; top: 15%; left: 10%; opacity: 0.8; }
  &:nth-child(2) { width: 30px; height: 30px; top: 25%; left: 30%; opacity: 0.6; }
  &:nth-child(3) { width: 35px; height: 35px; top: 20%; left: 50%; opacity: 0.7; }
  &:nth-child(4) { width: 45px; height: 45px; top: 15%; left: 70%; opacity: 0.8; }
  &:nth-child(5) { width: 35px; height: 35px; top: 25%; left: 90%; opacity: 0.6; }

  /* Middle section stars */
  &:nth-child(6) { width: 25px; height: 25px; top: 45%; left: 5%; opacity: 0.5; }
  &:nth-child(7) { width: 38px; height: 38px; top: 55%; left: 25%; opacity: 0.7; }
  &:nth-child(8) { width: 42px; height: 42px; top: 48%; left: 45%; opacity: 0.8; }
  &:nth-child(9) { width: 28px; height: 28px; top: 52%; left: 65%; opacity: 0.6; }
  &:nth-child(10) { width: 32px; height: 32px; top: 50%; left: 85%; opacity: 0.7; }

  /* Bottom section stars */
  &:nth-child(11) { width: 35px; height: 35px; top: 75%; left: 15%; opacity: 0.7; }
  &:nth-child(12) { width: 28px; height: 28px; top: 80%; left: 35%; opacity: 0.5; }
  &:nth-child(13) { width: 40px; height: 40px; top: 70%; left: 55%; opacity: 0.8; }
  &:nth-child(14) { width: 32px; height: 32px; top: 85%; left: 75%; opacity: 0.6; }
  &:nth-child(15) { width: 36px; height: 36px; top: 78%; left: 95%; opacity: 0.7; }

  /* Small decorative stars */
  &:nth-child(16) { width: 15px; height: 15px; top: 35%; left: 18%; opacity: 0.4; }
  &:nth-child(17) { width: 18px; height: 18px; top: 62%; left: 88%; opacity: 0.5; }
  &:nth-child(18) { width: 12px; height: 12px; top: 40%; left: 92%; opacity: 0.3; }
  &:nth-child(19) { width: 16px; height: 16px; top: 88%; left: 42%; opacity: 0.4; }
  &:nth-child(20) { width: 14px; height: 14px; top: 32%; left: 72%; opacity: 0.3; }

  @keyframes twinkle {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    50% {
      opacity: 0.3;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const CurvedLines = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  animation: rotateLine 20s linear infinite;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    animation: shine 4s ease-in-out infinite;
  }

  &::before {
    transform: rotate(30deg);
    animation-delay: -2s;
  }

  &::after {
    transform: rotate(-30deg);
    animation-delay: -1s;
  }

  @keyframes rotateLine {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  @keyframes shine {
    0% {
      border-color: rgba(255, 255, 255, 0.1);
      box-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
    }
    50% {
      border-color: rgba(255, 255, 255, 0.3);
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
    }
    100% {
      border-color: rgba(255, 255, 255, 0.1);
      box-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
    }
  }
`;

const MainContent = styled.div`
  min-height: 100vh;
  width: 100%;
  position: relative;
  z-index: 1;
`;

const Content = styled.div`
  position: relative;
  color: white;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  
  img {
    width: 200px;
    height: auto;
  }
`;

const ImpressumContent = styled.div`
  text-align: center;
  color: #ffffff;
  max-width: 600px;
  margin: 0 auto;
  
  h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: #ffd700; /* Gold color */
  }
  
  p {
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  
  a {
    color: #ffd700;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Impressum: React.FC = () => {
  return (
    <>
      <Layout color1={'#000428'} color2={'#004e92'} degree={45}>
        <PageContainer>
          <ChampionsPattern />
          <CurvedLines />
          <StarsContainer>
            {[...Array(20)].map((_, index) => (
              <Star key={index} />
            ))}
          </StarsContainer>
        </PageContainer>
        <MainContent>
          <Content>
            <LogoContainer>
              <img src='/images/club-logo.png' alt="SV Gold-Blau Augsburg Logo" />
            </LogoContainer>
            <ImpressumContent>
              <h2>Angaben gemäß § 5 TMG</h2>
              <p>SV Gold-Blau Augsburg e. V</p>
              <p>Unterer Talweg 100</p>
              <p>86179 Augsburg</p>
              <p><a href="https://maps.google.com/?q=Unterer+Talweg+100,86179+Augsburg" target="_blank" rel="noopener noreferrer">Auf der Karte zeigen</a></p>
              <p>&nbsp;</p>
              <p>Telefon: 0171 - 783 45 86</p>
              <p>E-Mail: <a href="mailto:rudolf.strohmaier@t-online.de">rudolf.strohmaier@t-online.de</a></p>
              <p>&nbsp;</p>
              <p><strong>Vertreten durch</strong></p>
              <p>Rudolf Strohmaier als Vorstand des SV Gold-Blau Augsburg e. V.</p>
              <p>&nbsp;</p>
              <p><strong>Registereintrag</strong></p>
              <p>Eintragung im Vereinsregister.</p>
              <p>Registergericht: Amtsgericht Augsburg</p>
              <p>Registernummer: VR 2760</p>
            </ImpressumContent>
          </Content>
        </MainContent>
        <Footer />
      </Layout>
    </>
  );
};

export default Impressum;