import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Calendar.css';

type Event = {
  id: number;
  title: string;
  date: Date;
  time: string;
  description: string;
};

export const CalendarView: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  
  const generateEvents = (): Event[] => {
    const events: Event[] = [];
    let currentDate = new Date(2025, 2, 1);
    const endDate = new Date(2026, 2, 1);
    let id = 1;

    while (currentDate < endDate) {
      if (currentDate.getDay() === 3 || currentDate.getDay() === 5) {
        events.push({
          id: id++,
          title: "Fußballtraining",
          date: new Date(currentDate),
          time: "18:30",
          description: "Training"
        });
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return events;
  };

  const events = generateEvents();

  const selectedDateEvents = selectedDate 
    ? events.filter(event => 
        event.date.getFullYear() === selectedDate.getFullYear() &&
        event.date.getMonth() === selectedDate.getMonth() &&
        event.date.getDate() === selectedDate.getDate()
      )
    : [];

  const handleDateClick = (date: Date) => {
    if (selectedDate && 
        date.getFullYear() === selectedDate.getFullYear() &&
        date.getMonth() === selectedDate.getMonth() &&
        date.getDate() === selectedDate.getDate()) {
      setSelectedDate(null);
    } else {
      setSelectedDate(date);
    }
  };

  const tileContent = ({ date }: { date: Date }) => {
    const hasEvent = events.some(event => 
      event.date.getFullYear() === date.getFullYear() &&
      event.date.getMonth() === date.getMonth() &&
      event.date.getDate() === date.getDate()
    );

    return hasEvent ? (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '4px'
      }}>
        <div style={{
          width: '6px',
          height: '6px',
          backgroundColor: '#f63b3b',
          borderRadius: '50%'
        }}></div>
      </div>
    ) : null;
  };

  return (
    <div className="w-full flex justify-center p-4">
      <div className="lg:flex lg:gap-8 lg:items-start">
        <div className="w-fit">
          <Calendar
            onChange={(date) => handleDateClick(date as Date)}
            value={selectedDate}
            className="rounded-lg shadow-lg bg-transparent"
            tileContent={tileContent}
          />
        </div>
        
        {selectedDate && (
          <div className="mt-4 lg:mt-0 bg-transparent rounded-lg shadow-lg p-4" style={{ width: '354px' }}>
            <h3 className="text-xl font-bold mb-4 text-white text-center">
              Termine für {selectedDate.toLocaleDateString()}
            </h3>
            {selectedDateEvents.length > 0 ? (
              <div className="space-y-4">
                {selectedDateEvents.map(event => (
                  <div 
                    key={event.id}
                    className="border-l-4 border-blue-500 pl-4 py-2"
                  >
                    <h4 className="font-semibold text-white">{event.title}</h4>
                    <p className="text-gray-300">{event.time}</p>
                    <p className="text-gray-200">{event.description}</p>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-300">Keine Termine für diesen Tag.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}; 