import { cn } from "../lib/utils.ts";
import React from "react";
import { useNavigate } from 'react-router-dom';

export const BentoGrid = ({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div className="grid gap-4 max-w-7xl mx-auto">
      {/* First row - 3 columns */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {React.Children.toArray(children).slice(0, 3)}
      </div>
      
      {/* Second row - 2 columns (2/3 + 1/3) */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="md:col-span-2">
          {React.Children.toArray(children)[3]}
        </div>
        <div className="md:col-span-1">
          {React.Children.toArray(children)[4]}
        </div>
      </div>
      
      {/* Third row - 3 columns */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {React.Children.toArray(children).slice(5, 8)}
      </div>
      
      {/* Fourth row - 2 columns (2/3 + 1/3) */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="md:col-span-2">
          {React.Children.toArray(children)[8]}
        </div>
        <div className="md:col-span-1">
          {React.Children.toArray(children)[9]}
        </div>
      </div>
    </div>
  );
};

export const BentoGridItem = ({
  className,
  title,
  description,
  header,
  icon,
  link,
}: {
  className?: string;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  header?: React.ReactNode;
  icon?: React.ReactNode;
  link?: string;
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (link) {
      console.log('Navigating to:', link);
      navigate(link);
    }
  };

  return (
    <div
      onClick={handleClick}
      className={cn(
        "row-span-1 rounded-xl group/bento hover:shadow-xl transition duration-200 shadow-input dark:shadow-none p-4 dark:bg-black dark:border-white/[0.2] bg-transparent border border-transparent justify-between flex flex-col space-y-4 cursor-pointer",
        className
      )}
    >
      {header}
      <div className="group-hover/bento:translate-x-2 transition duration-200">
        {/* {icon} */}
        <div className="font-sans font-bold text-white text-center dark:text-neutral-200 mb-2 mt-2">
          {title}
        </div>
      </div>
    </div>
  );
};
