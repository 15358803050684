import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Layout } from '../components/Layout.tsx';
import { Footer } from '../components/Footer.tsx';


interface Sparkle {
  left: number;
  top: number;
  size: number;
  delay: number;
}

const PageContainer = styled.div`
  margin: 0;
  padding: 0;
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(180deg, #000428 0%, #004e92 100%);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

const sparkle = keyframes`
  0%, 100% { opacity: 0.2; transform: scale(0.8); }
  50% { opacity: 1; transform: scale(1.2); }
`;

const Sparkle = styled.div<{ size: number; delay: number }>`
  position: absolute;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background: white;
  border-radius: 50%;
  animation: ${sparkle} ${props => 2 + props.delay}s ease-in-out infinite;
  filter: blur(1px);
`;
// min-height: 100vh;
const MainContent = styled.div`
  min-height: 85vh;
  width: 100%;
  position: relative;
  z-index: 1;
`;

const Content = styled.div`
  position: relative;
  color: white;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0;
  
  img {
    max-width: 200px;
    height: auto;
  }
`;

const Section = styled.section`
  margin: 2rem 0;
  color: white;
`;

const SectionTitle = styled.h2`
  color: #FFD700;
  font-size: 1.8rem;
  margin-bottom: 1rem;
`;

const IntroText = styled.div`
  margin: 2rem 0;
  line-height: 1.8;
  
  p {
    margin-bottom: 1.5rem;
    text-align: justify;
  }
`;

const SubSection = styled.div`
  margin: 1.5rem 0;
`;

const SubTitle = styled.h3`
  color: #FFD700;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
`;

const LocationCard = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
`;

const MapLink = styled.button`
  background: transparent;
  color: #FFD700;
  border: 1px solid #FFD700;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 0.5rem;
  
  &:hover {
    background: rgba(255, 215, 0, 0.1);
  }
`;

const ContactInfo = styled.div`
  margin: 1rem 0;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
`;

const TrainingGroup = styled.div`
  margin-bottom: 2.5rem;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const StadiumShow: React.FC = () => {
  const generateSparkles = (): Sparkle[] => {
    const sparkles: Sparkle[] = [];
    for (let i = 0; i < 100; i++) {
      sparkles.push({
        left: Math.random() * 100,
        top: Math.random() * 70,
        size: Math.random() * 3 + 1,
        delay: Math.random() * 2,
      });
    }
    return sparkles;
  };

  return (
    <>
      <Layout color1={'#000428'} color2={'#004e92'} degree={45}>
      <PageContainer>
        {generateSparkles().map((sparkle, index) => (
          <Sparkle
            key={`sparkle-${index}`}
            style={{
              left: `${sparkle.left}%`,
              top: `${sparkle.top}%`,
            }}
            size={sparkle.size}
            delay={sparkle.delay}
          />
        ))}
      </PageContainer>
      <MainContent>
        <Content>
          <LogoContainer>
            <img src='/images/club-logo.png' alt="SV Gold-Blau Augsburg Logo" />
          </LogoContainer>

          <Section>
            <IntroText>
              <p>Tribal Fusion bzw. Fusion Style Bellydance verbindet Elemente aus dem klassischen orientalischen Tanz (Jamily Salimpour Format) mit modernen Tanzstilen wie z.B. Urban dance, Jazz und Modern. Es ist eine sehr binnenkörperliche und elegante Tanzart, die hilft die Körperhaltung, Beweglichkeit und vor allem das eigene Körpergefühl zu verbessern.</p>
              <p>In meinen Kursen bereite ich unsere Körper mit speziellen Mobilisation- und Muskelübungen auf das Training vor, sodass der Körper bereit ist, diesen eleganten Tanz zu lernen und in sich aufzunehmen. Dabei kann ich sehr gut live und online auf die einzelnen Bedürfnisse meiner Teilnehmer eingehen und sie genauestens korrigieren. Als international tätige Tanzdozentin mit mehreren erfolgreich abgeschlossenen Tanzweiterbildungen und zusätzlicher postnataler Fitnessausbildung darf ich dabei auf ein sehr weit gefächertes Repertoire an Erfahrung und Trainingsvariationen schöpfen.</p>
              <p>Ich würde mich freuen, dich in meinen Kursen kennenlernen zu dürfen.</p>
            </IntroText>
          </Section>

          <Section>
            <SectionTitle>Trainingszeiten</SectionTitle>
            
            <TrainingGroup>
              <SubSection>
                <SubTitle>Wann</SubTitle>
                <p>Montags 18:45 - 20:00</p>
              </SubSection>
              <SubSection>
                <SubTitle>Info</SubTitle>
                <p>Fusion Style Bellydance - leichte Mittelstufe (Tanztechnik-Training und Komibs)</p>
              </SubSection>
              <SubSection>
                <SubTitle>Wo</SubTitle>
                <LocationCard>
                  <h4>Sporthalle Erhard Wunderlich</h4>
                  <p>Ulrich-Hofmaier-Straße 30</p>
                  <p>86159 Augsburg</p>
                  <MapLink>Auf der Karte zeigen</MapLink>
                </LocationCard>
              </SubSection>
            </TrainingGroup>

            {/* Additional training groups */}
            <TrainingGroup>
              <SubSection>
                <SubTitle>Wann</SubTitle>
                <p>Mittwochs 18:45 - 19:50</p>
              </SubSection>
              <SubSection>
                <SubTitle>Info</SubTitle>
                <p>Liquid Fusion Bellydance – leichte Mittelstufe (Fließende Tanztechnik und Kombis)</p>
              </SubSection>
              <SubSection>
                <SubTitle>Wo</SubTitle>
                <p>Gleiche Lokation wie vorherige</p>
              </SubSection>
            </TrainingGroup>

            {/* Add remaining training groups similarly */}
          </Section>

          <Section>
            <SubTitle>Besatzung</SubTitle>
            
            <ContactInfo>
              <h4>Tanzlehrer</h4>
              <p>Melanie Sarina Baumeister</p>
            </ContactInfo>
          </Section>

        </Content>
      </MainContent>
      <Footer />
      </Layout>
    </>
  );
};

export default StadiumShow;